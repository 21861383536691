* {
    font-family: 'Google Sans'
}

html,
body {
    background-color: rgb(215, 48, 48);
    height: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

html,
body,
header,
.carousel {
    height: 80vh;
}

html {
    scroll-behavior: smooth;
}

@media (max-width: 740px) {

    html,
    body,
    header,
    .carousel {
        height: 80vh;
    }
}

@media (min-width: 800px) and (max-width: 850px) {

    html,
    body,
    header,
    .carousel {
        height: 80vh;
    }
}

@media (min-width: 800px) and (max-width: 850px) {
    .navbar:not(.top-nav-collapse) {
        background: #929FBA !important;
    }
}

.leaflet-container {
    width: 100%;
    height: 100%;
}