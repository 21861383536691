/* Required height of parents of the Full Page Carousel for proper displaying carousel itself */

html, body, .view {
    height: 100%;
}

/* Full Page Carousel itself*/

.carousel {
    height: 100%;
}

.carousel .carousel-inner {
    height: 100%;
}

.carousel .carousel-inner .carousel-item, .carousel .carousel-inner .active {
    height: 100%;
}

/* Navbar animation */

.navbar {
    background-color: rgba(0, 0, 0, 0.2);
}

.top-nav-collapse {
    background-color: #1C2331;
}

/* Adding color to the Navbar on mobile */

@media only screen and (max-width: 768px) {
    .navbar {
        background-color: #1C2331;
    }
}

/* Footer color for sake of consistency with Navbar */

.page-footer {
    background-color: #1C2331;
}
